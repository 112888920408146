@import '../../../../styles/theme/breakpoints.scss';

.bannerSlider {
    position: relative;

    .slide {
        height: calc(400px + (500 - 400) * ((100vw - 1440px) / (2560 - 1440)));
        @media (max-width: $desktop) {
            padding: 0 6rem;
            height: 28rem !important;
        }
        @media (max-width: $tablet) {
            padding: 3rem 4rem;
            height: 40rem !important;
        }
        @media (max-width: $mobile) {
            padding: 0 0 4rem !important;
            height: 40rem !important;
        }
    }

    a {
        @media (max-width: $mobile) {
            width: 100%;
        }
    }

    .imgContainer {
        width: 100%;
        height: calc(300px + (300 - 200) * ((100vw - 1440px) / (2560 - 1440)));
        @media (max-width: $desktop) {
            width: 100%;
            height: 100%;
        }
        @media (max-width: $mobile) {
            margin-top: 2rem;
            height: 180px !important;
        }
        @media (max-width: $tablet) {
            width: 100%;
            height: 300px;
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    .arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(0, 0, 0, 0.5);
        color: #ffffff;
        font-size: 2rem;
        padding: 0.5rem;
        border: none;
        cursor: pointer;
        z-index: 10;

        &:focus {
            outline: none;
        }
    }

    .prev {
        left: 10px;
    }

    .next {
        right: 10px;
    }
}

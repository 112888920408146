@import '../../../../styles/theme/breakpoints.scss';

.newsLetter2 {
    padding-block: calc(80px + (100 - 80) * ((100vw - 1440px) / (2560 - 1440)));
    background-color: #bbb;
}
.form {
    @media (min-width: $mobile) {
        display: grid;
        grid-template-columns: 4fr 1.8fr;
        grid-gap: 1rem;
    }
}
.title {
    align-self: flex-start;
}

.spinner {
    --bs-spinner-width: 1rem;
    --bs-spinner-height: 1rem;
}

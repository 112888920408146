@import '../../../../styles/theme/breakpoints.scss';

.heroFullCasper {
    position: relative;
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: center;
}

.sectionTitle {
    margin-bottom: 2rem;
    @media (min-width: $desktop) {
        width: 14ch;
    }
}

.desc {
    margin-bottom: 3rem;
}

// .blocksContainer {
//     position: absolute;
//     left: 5rem;
//     top: 40rem;

//     > img {
//         position: absolute;
//         left: 0px;
//         bottom: 0px;
//         width: calc(15rem + 10.5vw);
//         height: auto;
//         transform: translateY(-1000px);
//         animation: blockAppear 2s forwards;

//         @for $i from 1 through 4 {
//             &:nth-child(#{$i}) {
//                 margin-bottom: calc(5rem * $i);
//                 left: calc(4rem * $i);
//                 animation-delay: 200ms * $i;
//             }
//         }
//     }
// }

// @keyframes blockAppear {
//     0% {
//         transform: translateY(-1000px);
//     }

//     100% {
//         transform: translateY(0px);
//     }
// }

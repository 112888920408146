@import '../../../../styles/theme/breakpoints.scss';

.ContentFullScreen2 {
    position: relative;
}
.bgImage {
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@import '../../../../styles/theme/breakpoints.scss';

.ctaRight {
    background-color: #ddd;
    padding-block: calc(100px + (120 - 100) * ((100vw - 1440px) / (2560 - 1440)));

    a {
        @media (max-width: $mobile) {
            width: 100%;
        }
    }
}

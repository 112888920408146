@import '../../../../styles/theme/breakpoints.scss';

.sectionTitleSlide {
    letter-spacing: 0px;
    margin-bottom: 3rem;
}

.carouselButtonGroup {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.cardBody {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: calc(600px + (900 - 600) * ((100vw - 1440px) / (2560 - 1440))) !important;
    @media (max-width: $tablet) {
        height: calc(500px + (600 - 500) * ((100vw - 320px) / (991 - 320))) !important;
    }
    align-items: center;
}

.carouselCardExpand {
    width: 50% !important;
}

.cardsContainer {
    @media (min-width: $tablet) {
        margin-left: 10%;
    }
}
